export const environment = {
    production: false,
    showReference: 3,
    services: {
        'bs-administration': {
            uri: 'https://administration.service.boekscout.dev/',
            token: true,
        },
        'bs-data': {
            uri: 'https://data.service.boekscout.dev/',
            token: true,
        },
    },
};
