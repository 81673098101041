import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {Router, RouterLink, RouterOutlet} from "@angular/router";
import {BsSharedDialogService, BsSharedDialogStackComponent, BsSharedMenuItemModel, BsSharedPartBodyComponent, BsSharedPartMarginalComponent} from "@boekscout/bs-shared";
import {BehaviorSubject, Observable, switchMap} from "rxjs";
import {BsCoreAuthenticatedService, BsCoreErrorService} from "@boekscout/bs-core";
import {TranslateService} from "@ngx-translate/core";
import {AsyncPipe, JsonPipe, NgClass} from "@angular/common";

@Component({
    selector: 'app-root',
    standalone: true,
    imports: [
        RouterOutlet,
        BsSharedDialogStackComponent,
        BsSharedPartMarginalComponent,
        BsSharedPartBodyComponent,
        RouterLink,
        NgClass,
        AsyncPipe,
        JsonPipe,
    ],
    template: `
        <ng-template
                #errorDialog
                let-error="error"
        >
            <bs-shared-part-marginal>
                Helaas gaat er iets mis
            </bs-shared-part-marginal>
            <bs-shared-part-body class="tone-warning">
                @switch (error.code) {
                    @case ('authentication') {
                        <p>
                            Je authenticatie lijkt verlopen te zijn.
                        </p>
                        <p class="mb-0">
                            <a
                                    routerLink="/sign-out"
                                    class="link"
                            >
                                Opnieuw inloggen
                            </a>
                        </p>
                    }
                    @case ('authorization') {
                        <p class="text-center">
                            Het lijkt er op dat je iets probeert te doen waar je geen rechten voor hebt.
                        </p>
                    }
                    @case ('tooManyRequests') {
                        <p class="text-center">
                            Te veel verzoeken in periode ontvangen, probeer het later opnieuw
                        </p>
                    }
                    @case ('server') {
                        <p class="text-center">
                            Er lijkt iets mis te gaan aan de server kant. Probeer het later nog eens, neem anders contact op met info&#64;boekscout.nl
                        </p>
                    }
                    @default {
                        <p>
                            Er lijkt iets mis te gaan. Probeer het later nog eens, neem anders contact op met info&#64;boekscout.nl
                        </p>
                        <p>
                            <strong>Error code:</strong>
                            {{ error.code }}
                        </p>
                    }
                }
            </bs-shared-part-body>
        </ng-template>
        <bs-shared-dialog-stack/>
        @if (authenticated$ | async) {
            <header class="main-header">
                <a
                        [routerLink]="['/']"
                        class="logo"
                >
                    <img
                            src="/assets/images/logo/bs-basic-light.png"
                            alt="Boekscout"
                    >
                </a>
                <button
                        (click)="showNavigation$.next(!showNavigation$.getValue())"
                        class="menu-toggle"
                        type="button"
                >
                    Menu
                </button>
                <nav [ngClass]="{'show': showNavigation$ | async}">
                    <a href="">
                        Boeken
                    </a>
                    <a [routerLink]="'/basket'">
                        Winkelmand
                    </a>
                    <a [routerLink]="'/administration/order'">
                        Bestellingen
                    </a>
                    <a [routerLink]="'/me/profile'">
                        Profiel
                    </a>
                    <a [routerLink]="'/me/sign-out'">
                        Uitloggen
                    </a>
                </nav>
            </header>
        }
        <main>
            <router-outlet></router-outlet>
        </main>
    `,
    styles: [`
      header {
        grid-template-areas: "logo toggle" "nav nav";
        grid-template-columns: 1fr 5rem;
        background-color: var(--dark);
        margin-bottom: var(--gutter);
        padding: var(--padding);
        display: grid;

        .logo {
          grid-area: logo;
          
          img {
            max-height: 2rem;
          }
        }
        
        button {
          display: inline-block;
          width: auto;
        }
        
        nav {
          padding-top: var(--gutter);
          flex-direction: column;
          gap: var(--gutter);
          grid-area: nav;
          display: flex;
          
          &:not(.show) {
            display: none;
          }
          
          a {
            padding: calc(var(--padding) / 2) var(--padding);
            background-color: var(--tone-secondary-bg);
            color: var(--tone-secondary-color);
            border-radius: var(--radius);
            display: flex;
            align-items: center;
          }
        }
      }
      
      @media screen and (min-width: 960px) {
        header {
          grid-template-areas: ". logo nav .";
          grid-template-columns: 1fr 20rem minmax(40rem, 55rem) 1fr;
          
          button {
            display: none;
          }
          
          nav {
            flex-direction: row;
            justify-content: right;
            display: flex !important;
            padding: 0;
          }
        }
      }
      
      header ~ main {
        max-width: 77rem;
        margin: 0 auto;
        padding: 1rem;
      }
    `]
})
export class AppComponent implements OnInit {
    @ViewChild('errorDialog') errorDialog: TemplateRef<any>;

    authenticated$: Observable<boolean>;

    showNavigation$ = new BehaviorSubject(true);

    constructor(
        private authenticatedService: BsCoreAuthenticatedService,
        private dialogService: BsSharedDialogService,
        private translateService: TranslateService,
        private errorService: BsCoreErrorService,
        private router: Router,
    ) {
    }

    ngOnInit(): void {
        this.translateService.setDefaultLang('nl_NL');

        this.authenticated$ = this.authenticatedService.isAuthenticated();

        this
            .errorService
            .get()
            .subscribe((error) => {
                const onClose = this.dialogService.open(this.errorDialog, { error });
                onClose.subscribe(() => this.errorService.clear());
            });

        this.router.events.subscribe(() => {
            this.showNavigation$.next(false);
        })
    }
}
