import {ApplicationConfig, importProvidersFrom} from '@angular/core';
import {provideRouter, withRouterConfig} from '@angular/router';

import {routes} from './app.routes';
import {HttpClient, provideHttpClient} from "@angular/common/http";
import {TranslateLoader, TranslateModule} from "@ngx-translate/core";
import {TranslateHttpLoader} from "@ngx-translate/http-loader";
import {BsCoreAuthenticatedGuard, BsCoreGuestGuard, BsCoreRpcService} from "@boekscout/bs-core";
import {environment} from "../environments/environment";

export const appConfig: ApplicationConfig = {
    providers: [
        {
            provide: 'env',
            useValue: environment,
        },

        provideHttpClient(),

        importProvidersFrom(
            TranslateModule.forRoot({
                // missingTranslationHandler: {
                    // provide: MissingTranslationHandler,
                    // useClass: AppMissingTranslationHandler,
                // },
                loader: {
                    provide: TranslateLoader,
                    useFactory: function (http: HttpClient) {
                        return new TranslateHttpLoader(
                            http,
                            './assets/i18n/',
                            // Cache busting issue ...
                            `.json`
                        );
                    },
                    deps: [HttpClient],
                },
            }),
        ),

        BsCoreRpcService,
        BsCoreAuthenticatedGuard,
        BsCoreGuestGuard,

        provideRouter(
            routes,
            withRouterConfig({
                paramsInheritanceStrategy: 'always',
            }),
        ),
    ],
};
