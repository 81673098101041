import {Routes} from '@angular/router';
import {BsCoreAuthenticatedGuard} from "@boekscout/bs-core";

export const routes: Routes = [
    {
        loadComponent: () => import('./components/app-home.component').then(m => m.AppHomeComponent),
        canActivate: [
            BsCoreAuthenticatedGuard,
        ],
        path: '',
    },
    {
        loadComponent: () => import('./components/app-basket.component').then(m => m.AppBasketComponent),
        canActivate: [
            BsCoreAuthenticatedGuard,
        ],
        path: 'basket',
    },
    {
        loadChildren: () => import('../administration/portal-administration.routes').then(m => m.portalAdministrationRoutes),
        canActivate: [
            BsCoreAuthenticatedGuard,
        ],
        path: 'administration',
    },
    {
        loadChildren: () => import('../book/portal-book.routes').then(m => m.portalBookRoutes),
        canActivate: [
            BsCoreAuthenticatedGuard,
        ],
        path: 'book',
    },
    {
        loadChildren: () => import('../me/portal-me.routes').then(m => m.portalMeRoutes),
        path: 'me',
    },
];
